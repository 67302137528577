import * as React from "react";
import * as style from "./link.module.scss";
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";

const Link = ({
  children,
  to,
  className,
  activeClassName,
  partiallyActive,
  iconBefore,
  iconAfter,
  ...other
}) => {
  const internal = /^\/(?!\/)/.test(to);
  const file = /\.[0-9a-z]+$/i.test(to);
  const contact = to.startsWith("mailto:") || to.startsWith("tel:");
  const anchor = to.startsWith("#");
  const hasIcon = iconBefore || iconAfter;
  const classNames = `${hasIcon ? style.Link___icon : ""} ${
    className ? className : ""
  }`.trim();
  if (internal) {
    if (file) {
      return (
        <a
          href={to}
          className={classNames}
          target="_blank"
          rel="nofollow noreferrer"
          {...other}
        >
          {iconBefore && iconBefore}
          {children}
          {iconAfter && iconAfter}
        </a>
      );
    }
    return (
      <GatsbyLink
        to={to}
        className={classNames}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {iconBefore && iconBefore}
        {children}
        {iconAfter && iconAfter}
      </GatsbyLink>
    );
  } else if (anchor || contact) {
    return (
      <a href={to} className={classNames} {...other}>
        {iconBefore && iconBefore}
        {children}
        {iconAfter && iconAfter}
      </a>
    );
  }
  return (
    <a
      href={to}
      className={classNames}
      target="_blank"
      rel="nofollow noreferrer"
      {...other}
    >
      {iconBefore && iconBefore}
      {children}
      {iconAfter && iconAfter}
    </a>
  );
};

export default Link;

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  iconBefore: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconAfter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Link.displayName = "Link";
