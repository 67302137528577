import { useMediaQuery } from "react-responsive";

const mobileBreakpoint = { maxWidth: 767 };
const tabletBreakpoint = { minWidth: 768, maxWidth: 1023 };
const desktopBreakpoint = { minWidth: 1024 };

const notDesktopBreakpoint = { maxWidth: 1023 };
const notMobileBreakpoint = { minWidth: 768 };

const DesktopView = ({ children }) => {
  const isDesktop = useMediaQuery(desktopBreakpoint);
  return isDesktop ? children : null;
};

const TabletView = ({ children }) => {
  const isTablet = useMediaQuery(tabletBreakpoint);
  return isTablet ? children : null;
};

const MobileView = ({ children }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return isMobile ? children : null;
};

const NotDesktopView = ({ children }) => {
  const isNotDesktop = useMediaQuery(notDesktopBreakpoint);
  return isNotDesktop ? children : null;
};

const NotMobileView = ({ children }) => {
  const isNotMobile = useMediaQuery(notMobileBreakpoint);
  return isNotMobile ? children : null;
};

export {
  desktopBreakpoint,
  tabletBreakpoint,
  mobileBreakpoint,
  notDesktopBreakpoint,
  notMobileBreakpoint,
  DesktopView,
  TabletView,
  MobileView,
  NotDesktopView,
  NotMobileView,
};
