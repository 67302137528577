exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-citius-contact-jsx": () => import("./../../../src/pages/citius/contact.jsx" /* webpackChunkName: "component---src-pages-citius-contact-jsx" */),
  "component---src-pages-citius-contact-sent-jsx": () => import("./../../../src/pages/citius/contact/sent.jsx" /* webpackChunkName: "component---src-pages-citius-contact-sent-jsx" */),
  "component---src-pages-citius-organization-jsx": () => import("./../../../src/pages/citius/organization.jsx" /* webpackChunkName: "component---src-pages-citius-organization-jsx" */),
  "component---src-pages-citius-our-spaces-index-jsx": () => import("./../../../src/pages/citius/our-spaces/index.jsx" /* webpackChunkName: "component---src-pages-citius-our-spaces-index-jsx" */),
  "component---src-pages-citius-our-spaces-it-infrastructures-jsx": () => import("./../../../src/pages/citius/our-spaces/it-infrastructures.jsx" /* webpackChunkName: "component---src-pages-citius-our-spaces-it-infrastructures-jsx" */),
  "component---src-pages-citius-our-spaces-labs-jsx": () => import("./../../../src/pages/citius/our-spaces/labs.jsx" /* webpackChunkName: "component---src-pages-citius-our-spaces-labs-jsx" */),
  "component---src-pages-citius-presentation-jsx": () => import("./../../../src/pages/citius/presentation.jsx" /* webpackChunkName: "component---src-pages-citius-presentation-jsx" */),
  "component---src-pages-citius-science-society-index-jsx": () => import("./../../../src/pages/citius/science-society/index.jsx" /* webpackChunkName: "component---src-pages-citius-science-society-index-jsx" */),
  "component---src-pages-citius-science-society-outreach-jsx": () => import("./../../../src/pages/citius/science-society/outreach.jsx" /* webpackChunkName: "component---src-pages-citius-science-society-outreach-jsx" */),
  "component---src-pages-citius-science-society-singular-science-jsx": () => import("./../../../src/pages/citius/science-society/singular-science.jsx" /* webpackChunkName: "component---src-pages-citius-science-society-singular-science-jsx" */),
  "component---src-pages-citius-science-society-visits-jsx": () => import("./../../../src/pages/citius/science-society/visits.jsx" /* webpackChunkName: "component---src-pages-citius-science-society-visits-jsx" */),
  "component---src-pages-citius-transparency-jsx": () => import("./../../../src/pages/citius/transparency.jsx" /* webpackChunkName: "component---src-pages-citius-transparency-jsx" */),
  "component---src-pages-events-conferences-jsx": () => import("./../../../src/pages/events/conferences.jsx" /* webpackChunkName: "component---src-pages-events-conferences-jsx" */),
  "component---src-pages-events-inside-the-lab-jsx": () => import("./../../../src/pages/events/inside-the-lab.jsx" /* webpackChunkName: "component---src-pages-events-inside-the-lab-jsx" */),
  "component---src-pages-events-lectures-distinguished-researchers-jsx": () => import("./../../../src/pages/events/lectures/distinguished-researchers.jsx" /* webpackChunkName: "component---src-pages-events-lectures-distinguished-researchers-jsx" */),
  "component---src-pages-events-lectures-index-jsx": () => import("./../../../src/pages/events/lectures/index.jsx" /* webpackChunkName: "component---src-pages-events-lectures-index-jsx" */),
  "component---src-pages-events-lectures-keynote-speeches-jsx": () => import("./../../../src/pages/events/lectures/keynote-speeches.jsx" /* webpackChunkName: "component---src-pages-events-lectures-keynote-speeches-jsx" */),
  "component---src-pages-events-lectures-other-centers-jsx": () => import("./../../../src/pages/events/lectures/other-centers.jsx" /* webpackChunkName: "component---src-pages-events-lectures-other-centers-jsx" */),
  "component---src-pages-events-upcoming-events-archive-jsx": () => import("./../../../src/pages/events/upcoming-events/archive.jsx" /* webpackChunkName: "component---src-pages-events-upcoming-events-archive-jsx" */),
  "component---src-pages-events-upcoming-events-index-jsx": () => import("./../../../src/pages/events/upcoming-events/index.jsx" /* webpackChunkName: "component---src-pages-events-upcoming-events-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-archive-index-jsx": () => import("./../../../src/pages/news/archive/index.jsx" /* webpackChunkName: "component---src-pages-news-archive-index-jsx" */),
  "component---src-pages-news-in-the-media-archive-jsx": () => import("./../../../src/pages/news/in-the-media/archive.jsx" /* webpackChunkName: "component---src-pages-news-in-the-media-archive-jsx" */),
  "component---src-pages-news-in-the-media-index-jsx": () => import("./../../../src/pages/news/in-the-media/index.jsx" /* webpackChunkName: "component---src-pages-news-in-the-media-index-jsx" */),
  "component---src-pages-news-job-offers-archive-jsx": () => import("./../../../src/pages/news/job-offers/archive.jsx" /* webpackChunkName: "component---src-pages-news-job-offers-archive-jsx" */),
  "component---src-pages-news-job-offers-index-jsx": () => import("./../../../src/pages/news/job-offers/index.jsx" /* webpackChunkName: "component---src-pages-news-job-offers-index-jsx" */),
  "component---src-pages-news-news-archive-index-jsx": () => import("./../../../src/pages/news/news/archive/index.jsx" /* webpackChunkName: "component---src-pages-news-news-archive-index-jsx" */),
  "component---src-pages-news-news-index-jsx": () => import("./../../../src/pages/news/news/index.jsx" /* webpackChunkName: "component---src-pages-news-news-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-research-areas-jsx": () => import("./../../../src/pages/research/areas.jsx" /* webpackChunkName: "component---src-pages-research-areas-jsx" */),
  "component---src-pages-research-projects-archive-jsx": () => import("./../../../src/pages/research/projects-archive.jsx" /* webpackChunkName: "component---src-pages-research-projects-archive-jsx" */),
  "component---src-pages-research-projects-jsx": () => import("./../../../src/pages/research/projects.jsx" /* webpackChunkName: "component---src-pages-research-projects-jsx" */),
  "component---src-pages-research-publications-articles-jsx": () => import("./../../../src/pages/research/publications/articles.jsx" /* webpackChunkName: "component---src-pages-research-publications-articles-jsx" */),
  "component---src-pages-research-publications-books-jsx": () => import("./../../../src/pages/research/publications/books.jsx" /* webpackChunkName: "component---src-pages-research-publications-books-jsx" */),
  "component---src-pages-research-publications-congresses-jsx": () => import("./../../../src/pages/research/publications/congresses.jsx" /* webpackChunkName: "component---src-pages-research-publications-congresses-jsx" */),
  "component---src-pages-research-publications-index-jsx": () => import("./../../../src/pages/research/publications/index.jsx" /* webpackChunkName: "component---src-pages-research-publications-index-jsx" */),
  "component---src-pages-research-publications-theses-jsx": () => import("./../../../src/pages/research/publications/theses.jsx" /* webpackChunkName: "component---src-pages-research-publications-theses-jsx" */),
  "component---src-pages-search-index-jsx": () => import("./../../../src/pages/search/index.jsx" /* webpackChunkName: "component---src-pages-search-index-jsx" */),
  "component---src-pages-search-news-events-jsx": () => import("./../../../src/pages/search/news-events.jsx" /* webpackChunkName: "component---src-pages-search-news-events-jsx" */),
  "component---src-pages-search-people-jsx": () => import("./../../../src/pages/search/people.jsx" /* webpackChunkName: "component---src-pages-search-people-jsx" */),
  "component---src-pages-search-projects-jsx": () => import("./../../../src/pages/search/projects.jsx" /* webpackChunkName: "component---src-pages-search-projects-jsx" */),
  "component---src-pages-search-publications-jsx": () => import("./../../../src/pages/search/publications.jsx" /* webpackChunkName: "component---src-pages-search-publications-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-talent-doctoral-master-jsx": () => import("./../../../src/pages/talent/doctoral-master.jsx" /* webpackChunkName: "component---src-pages-talent-doctoral-master-jsx" */),
  "component---src-pages-talent-equality-jsx": () => import("./../../../src/pages/talent/equality.jsx" /* webpackChunkName: "component---src-pages-talent-equality-jsx" */),
  "component---src-pages-talent-hrs-4-r-jsx": () => import("./../../../src/pages/talent/hrs4r.jsx" /* webpackChunkName: "component---src-pages-talent-hrs-4-r-jsx" */),
  "component---src-pages-talent-join-us-degree-jsx": () => import("./../../../src/pages/talent/join-us/degree.jsx" /* webpackChunkName: "component---src-pages-talent-join-us-degree-jsx" */),
  "component---src-pages-talent-join-us-index-jsx": () => import("./../../../src/pages/talent/join-us/index.jsx" /* webpackChunkName: "component---src-pages-talent-join-us-index-jsx" */),
  "component---src-pages-talent-join-us-postdoc-jsx": () => import("./../../../src/pages/talent/join-us/postdoc.jsx" /* webpackChunkName: "component---src-pages-talent-join-us-postdoc-jsx" */),
  "component---src-pages-talent-join-us-predoc-jsx": () => import("./../../../src/pages/talent/join-us/predoc.jsx" /* webpackChunkName: "component---src-pages-talent-join-us-predoc-jsx" */),
  "component---src-pages-talent-live-citius-jsx": () => import("./../../../src/pages/talent/live-citius.jsx" /* webpackChunkName: "component---src-pages-talent-live-citius-jsx" */),
  "component---src-pages-talent-training-commission-jsx": () => import("./../../../src/pages/talent/training-commission.jsx" /* webpackChunkName: "component---src-pages-talent-training-commission-jsx" */),
  "component---src-pages-talent-training-program-doctoral-meeting-jsx": () => import("./../../../src/pages/talent/training-program/doctoral-meeting.jsx" /* webpackChunkName: "component---src-pages-talent-training-program-doctoral-meeting-jsx" */),
  "component---src-pages-talent-training-program-emprendelab-jsx": () => import("./../../../src/pages/talent/training-program/emprendelab.jsx" /* webpackChunkName: "component---src-pages-talent-training-program-emprendelab-jsx" */),
  "component---src-pages-talent-training-program-index-jsx": () => import("./../../../src/pages/talent/training-program/index.jsx" /* webpackChunkName: "component---src-pages-talent-training-program-index-jsx" */),
  "component---src-pages-talent-training-program-technology-jsx": () => import("./../../../src/pages/talent/training-program/technology.jsx" /* webpackChunkName: "component---src-pages-talent-training-program-technology-jsx" */),
  "component---src-pages-talent-training-program-technology-transfer-jsx": () => import("./../../../src/pages/talent/training-program/technology-transfer.jsx" /* webpackChunkName: "component---src-pages-talent-training-program-technology-transfer-jsx" */),
  "component---src-pages-talent-training-program-transversal-competences-jsx": () => import("./../../../src/pages/talent/training-program/transversal-competences.jsx" /* webpackChunkName: "component---src-pages-talent-training-program-transversal-competences-jsx" */),
  "component---src-pages-team-alumni-jsx": () => import("./../../../src/pages/team/alumni.jsx" /* webpackChunkName: "component---src-pages-team-alumni-jsx" */),
  "component---src-pages-team-directory-jsx": () => import("./../../../src/pages/team/directory.jsx" /* webpackChunkName: "component---src-pages-team-directory-jsx" */),
  "component---src-pages-team-management-jsx": () => import("./../../../src/pages/team/management.jsx" /* webpackChunkName: "component---src-pages-team-management-jsx" */),
  "component---src-pages-team-otherstaff-collab-fellowship-jsx": () => import("./../../../src/pages/team/otherstaff-collab-fellowship.jsx" /* webpackChunkName: "component---src-pages-team-otherstaff-collab-fellowship-jsx" */),
  "component---src-pages-team-otherstaff-other-jsx": () => import("./../../../src/pages/team/otherstaff-other.jsx" /* webpackChunkName: "component---src-pages-team-otherstaff-other-jsx" */),
  "component---src-pages-team-otherstaff-starting-jsx": () => import("./../../../src/pages/team/otherstaff-starting.jsx" /* webpackChunkName: "component---src-pages-team-otherstaff-starting-jsx" */),
  "component---src-pages-team-otherstaff-summer-fellowship-jsx": () => import("./../../../src/pages/team/otherstaff-summer-fellowship.jsx" /* webpackChunkName: "component---src-pages-team-otherstaff-summer-fellowship-jsx" */),
  "component---src-pages-team-researchers-collaborators-jsx": () => import("./../../../src/pages/team/researchers-collaborators.jsx" /* webpackChunkName: "component---src-pages-team-researchers-collaborators-jsx" */),
  "component---src-pages-team-researchers-postdoc-jsx": () => import("./../../../src/pages/team/researchers-postdoc.jsx" /* webpackChunkName: "component---src-pages-team-researchers-postdoc-jsx" */),
  "component---src-pages-team-researchers-predoc-jsx": () => import("./../../../src/pages/team/researchers-predoc.jsx" /* webpackChunkName: "component---src-pages-team-researchers-predoc-jsx" */),
  "component---src-pages-team-researchers-senior-jsx": () => import("./../../../src/pages/team/researchers-senior.jsx" /* webpackChunkName: "component---src-pages-team-researchers-senior-jsx" */),
  "component---src-pages-team-researchers-stay-jsx": () => import("./../../../src/pages/team/researchers-stay.jsx" /* webpackChunkName: "component---src-pages-team-researchers-stay-jsx" */),
  "component---src-pages-transfer-spin-offs-jsx": () => import("./../../../src/pages/transfer/spin-offs.jsx" /* webpackChunkName: "component---src-pages-transfer-spin-offs-jsx" */),
  "component---src-pages-videos-jsx": () => import("./../../../src/pages/videos.jsx" /* webpackChunkName: "component---src-pages-videos-jsx" */),
  "component---src-templates-events-event-template-jsx": () => import("./../../../src/templates/events/event-template.jsx" /* webpackChunkName: "component---src-templates-events-event-template-jsx" */),
  "component---src-templates-news-job-offer-template-jsx": () => import("./../../../src/templates/news/jobOffer-template.jsx" /* webpackChunkName: "component---src-templates-news-job-offer-template-jsx" */),
  "component---src-templates-news-news-template-jsx": () => import("./../../../src/templates/news/news-template.jsx" /* webpackChunkName: "component---src-templates-news-news-template-jsx" */),
  "component---src-templates-research-area-projects-template-jsx": () => import("./../../../src/templates/research/area-projects-template.jsx" /* webpackChunkName: "component---src-templates-research-area-projects-template-jsx" */),
  "component---src-templates-research-area-publications-template-jsx": () => import("./../../../src/templates/research/area-publications-template.jsx" /* webpackChunkName: "component---src-templates-research-area-publications-template-jsx" */),
  "component---src-templates-research-area-template-jsx": () => import("./../../../src/templates/research/area-template.jsx" /* webpackChunkName: "component---src-templates-research-area-template-jsx" */),
  "component---src-templates-research-author-projects-template-jsx": () => import("./../../../src/templates/research/author-projects-template.jsx" /* webpackChunkName: "component---src-templates-research-author-projects-template-jsx" */),
  "component---src-templates-research-author-publications-template-jsx": () => import("./../../../src/templates/research/author-publications-template.jsx" /* webpackChunkName: "component---src-templates-research-author-publications-template-jsx" */),
  "component---src-templates-research-project-template-jsx": () => import("./../../../src/templates/research/project-template.jsx" /* webpackChunkName: "component---src-templates-research-project-template-jsx" */),
  "component---src-templates-research-publication-template-jsx": () => import("./../../../src/templates/research/publication-template.jsx" /* webpackChunkName: "component---src-templates-research-publication-template-jsx" */),
  "component---src-templates-team-member-template-jsx": () => import("./../../../src/templates/team/member-template.jsx" /* webpackChunkName: "component---src-templates-team-member-template-jsx" */)
}

