// extracted by mini-css-extract-plugin
export var Footer = "footer-module--Footer--36307";
export var Footer_address = "footer-module--Footer_address--6b297";
export var Footer_copyright = "footer-module--Footer_copyright--31f89";
export var Footer_followUs = "footer-module--Footer_followUs--f89f8";
export var Footer_institutional = "footer-module--Footer_institutional--f6164";
export var Footer_logo = "footer-module--Footer_logo--fcce0";
export var Footer_logos = "footer-module--Footer_logos--6cb98";
export var Footer_logos_2 = "footer-module--Footer_logos_2--4b326";
export var Footer_logos_3 = "footer-module--Footer_logos_3--04c62";
export var Footer_main = "footer-module--Footer_main--a3995";
export var Footer_otherLinks = "footer-module--Footer_otherLinks--4a841";
export var Footer_quickLinks = "footer-module--Footer_quickLinks--c286f";
export var Footer_quickLinksCol = "footer-module--Footer_quickLinksCol--42164";
export var Footer_quickLinksColTitle = "footer-module--Footer_quickLinksColTitle--a5526";
export var slideIn = "footer-module--slide-in--da1fe";
export var slideInFromRight = "footer-module--slide-in-from-right--980b0";